import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup;
  // public service:ServicepageService
  constructor(private fb: FormBuilder,) {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone_number: ['', Validators.required],
      msg_subject: ['', Validators.required],
      message: ['', Validators.required],
    });
  }

  ngOnInit(): void {

  }
  onSubmit() {
    console.log(this.contactForm.value)
    // this.service.sendEmail(this.contactForm.value).subscribe((res: any) => {
    //   if (res.status == 200) {
    //     alert('success')
    //     this.contactForm.reset(); // Resets the form to initial state
    //     this.contactForm.value.clear(); 
    //     // this.service.statusChangeAlert("updated successfully..")
    //     // this.frns_reg_get();
    //   } else {
    //     alert('Failed')
    //     // this.service.errorMessageAlert('server down')
    //   }
    // })
  }

}
