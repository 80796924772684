import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Istudent } from '../istudent';
import { LoginserviceService } from '../loginservice.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-loginform',
  standalone: true,
  imports: [],
  templateUrl: './loginform.component.html',
  styleUrl: './loginform.component.scss'
})
export class LoginformComponent {
formgroup:FormGroup;
studentdata:Istudent[] =[];
constructor(private loginservice:LoginserviceService,private fb:FormBuilder){
  this.formgroup = this.fb.group({
    firstname:['',Validators.required],
    lastname:['',Validators.required],
    email:['',Validators.required],
    mobilenumber:['',Validators.required],

  })
}

submit(){
  if(this.formgroup.valid){
    this.loginservice.login(this.formgroup.value).subscribe((data)=>{
      this.studentdata.push(data)
      alert("login sucessfull")
      console.log(data)
    })
  }else{
    alert("login failed")
  }
}
}
