<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Terms & Conditions</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Terms & Conditions</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="privecy-area ptb-100">

    <div class="container">
  
      <div class="container">
        <h1>Terms and Conditions</h1>
        <p>These Terms and Conditions ("Terms") govern your use of the software, services, and website provided by Godavari Wave Technologies Pvt Ltd . By using our services, you agree to these Terms.</p>
      
        <h2>Use of Services</h2>
        <h3>1. Eligibility</h3>
        <p>You must be at least 18 years old to use our services. By accessing our services, you confirm that you meet this requirement.</p>
      
        <h3>2. User Responsibilities</h3>
        <ul>
          <li>You agree to provide accurate and truthful information when using our services.</li>
          <li>You will not use our services for unlawful or unauthorized purposes.</li>
          <li>You are responsible for maintaining the confidentiality of your account information.</li>
        </ul>
      
        <h3>3. Prohibited Activities</h3>
        <p>You agree not to:</p>
        <ul>
          <li>Reverse-engineer, decompile, or attempt to extract the source code of our software.</li>
          <li>Disrupt the operation of our services through hacking or other malicious activities.</li>
        </ul>
      
        <h2>Intellectual Property</h2>
        <p>All intellectual property rights related to our software, website, and services are owned by or licensed to Godavari Wave Technologies Pvt Ltd. Unauthorized use of our intellectual property is strictly prohibited.</p>
      
        <h2>Limitation of Liability</h2>
        <p>To the fullest extent permitted by law, Godavari Wave Technologies Pvt Ltd will not be liable for any indirect, incidental, or consequential damages arising from your use of our services.</p>
      
        <h2>Indemnification</h2>
        <p>You agree to indemnify and hold harmless Godavari Wave Technologies Pvt Ltd, its affiliates, and employees from any claims, losses, or damages resulting from your breach of these Terms or misuse of our services.</p>
      
        <h2>Termination</h2>
        <p>We reserve the right to suspend or terminate your access to our services at our discretion if you violate these Terms.</p>
      
        <h2>Governing Law</h2>
        <p>These Terms are governed by the laws of India. Any disputes will be resolved in the courts located in [Your Jurisdiction].</p>
      
        <h2>Changes to These Terms</h2>
        <p>We may update these Terms from time to time. Your continued use of our services constitutes your acceptance of the updated Terms.</p>
      
        <h2>Contact Us</h2>
        <address>
          <strong>Godavari Wave Technologies Pvt Ltd</strong><br>
          Email: <a href="mailto:info&#64;godavariwave.com">info&#64;godavariwave.com</a><br>
          Phone: +91-7799822811<br>
          Address: 3rd Floor, BVR Enclave ,Above Yamaha Showroom,Morampudi , Rajamahendravaram .
        </address>
        <p><span style="color:red">Note</span>:Last Updated on 17-12-2024</p>
      </div>
    </div>
</section>