import { Injectable } from '@angular/core';
import { Istudent } from './istudent';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class LoginserviceService {

  constructor(private http:HttpClient) { }
login(body:Partial<Istudent>):Observable<Istudent>{
  const loginurl = "http:localhost://3000/api/student";
  return this.http.post<Istudent>(loginurl,body)
}
}
