<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Privacy Policy</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">privacy-policy</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="privecy-area ptb-100">
    <div class="container">
        <h1>Privacy Policy</h1>
        <p>Welcome to Godavari Wave Technologies Pvt Ltd . We value your privacy and are committed to protecting your personal data.</p>
      
        <h2>Information We Collect</h2>
        <h3>1. Personal Information</h3>
        <p>We may collect personally identifiable information such as your name, email address, phone number, and other details you provide while using our services.</p>
      
        <h3>2. Non-Personal Information</h3>
        <p>We may collect non-personal information, such as IP addresses, browser types, and usage patterns, to enhance your experience and improve our services.</p>
      
        <h2>How We Use Your Information</h2>
        <ul>
          <li>To provide, operate, and maintain our software and services.</li>
          <li>To communicate with you, including sending updates and promotional materials.</li>
          <li>To analyze usage patterns and improve our offerings.</li>
          <li>To comply with legal obligations.</li>
        </ul>
      
        <h2>Data Sharing and Disclosure</h2>
        <p>We do not sell, rent, or share your personal information with third parties except as described below:</p>
        <ul>
          <li><strong>Service Providers:</strong> We may share information with third-party vendors who perform services on our behalf.</li>
          <li><strong>Legal Requirements:</strong> We may disclose information when required by law or to protect our legal rights.</li>
        </ul>
      
        <h2>Data Security</h2>
        <p>We use industry-standard security measures to protect your information from unauthorized access, alteration, disclosure, or destruction.</p>
      
        <h2>Your Rights</h2>
        <p>You have the right to:</p>
        <ul>
          <li>Access the personal data we hold about you.</li>
          <li>Request correction or deletion of your data.</li>
          <li>Withdraw consent for data processing where applicable.</li>
        </ul>
        <p>To exercise these rights, please contact us at <a href="mailto:info&#64;godavariwave.com">info&#64;godavariwave.com</a>.</p>
      
        <h2>Changes to This Policy</h2>
        <p>We may update this privacy policy from time to time. Changes will be posted on this page with an updated revision date.</p>
      
        <h2>Contact Us</h2>
        <p>If you have questions or concerns about this privacy policy, please contact us:</p>
        <address>
          <strong>Godavari Wave Technologies Pvt Ltd</strong><br>
          Email: <a href="mailto:info&#64;Godavariwave.com">info&#64;godavariwave.com</a><br>
          Phone: +91-77998228811<br>
          Address: 3rd Floor, BVR Enclave ,Above Yamaha Showroom,Morampudi , Rajamahendravaram .
        </address>
        <p><span style="color:red">Note</span>:Last Updated on 17-12-2024</p>
      </div> 
</section>