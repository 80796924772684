<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Solutions</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Solutions</li>
            </ul>
        </div>
    </div>
    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>
<section class="home-service-area pb-70 pt-100 ">
    <div class="container">
        <div class="section-title">
            <span>Smart Services</span>
            <h2>Godavari Wave Provide All Kind of Tech Solutions</h2>
            <p> Godavari Wave delivers comprehensive tech solutions tailored to meet diverse business needs. From
                software development to IT consulting, we ensure innovation and excellence.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">

                        <img src="assets/images/service/ss5.png" alt="service" />
                    </div>
                    <div class="service-content">
                        <h3>Navobarmer</h3>
                        <p>Creating visually captivating designs that elevate user experiences and brand identity.</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/ss2.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Lampsi</h3>
                        <p>Building robust and scalable solutions that drive digital transformation and business
                            success.</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/ss3.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>TipTop</h3>
                        <p>
                            Ensuring quality and performance through meticulous testing and validation for flawless
                            software delivery.</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/ss4.png" alt="service" />
                    </div>
                    <div class="service-content">
                        <h3>Sports Connect</h3>
                        <p>Streamlining IT operations and aligning technology with business goals for enhanced
                            efficiency and growth.</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                       
                        <img src="assets/images/service/ss1.png" alt="service" />
                    </div>
                    <div class="service-content">
                        <h3> My nrega</h3>
                        <p>
                            Protecting your digital assets with comprehensive security solutions that safeguard against
                            evolving threats.</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/ss8.png" alt="service" />
                    </div>
                    <div class="service-content">
                        <h3> Telugu Neta</h3>
                        <p> Enabling seamless and reliable wireless connectivity for enhanced communication and data
                            transfer.</p>
                        <!-- <a routerLink="/solutions-details" class="line-bnt">Read More</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>