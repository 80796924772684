<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>About</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">About</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-company-area bg-color">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>Our Godavari Wave Tech</span>
                        <h2>Innovative IT Helping Service Worldwide</h2>
                        <p>Godavari Wave stands as a pioneering technology partner, transforming complex digital challenges into strategic opportunities for businesses across the global landscape. Our comprehensive suite of tech solutions is meticulously designed to empower organizations with cutting-edge technological capabilities that drive growth, efficiency, and competitive advantage.</p>
                        
                        <p>Our core philosophy revolves around a deep, nuanced understanding of each client's unique technological ecosystem. We don't just provide services; we craft bespoke technological strategies that align perfectly with your business objectives. From sophisticated software development and robust IT infrastructure management to advanced cybersecurity protocols and innovative wireless connectivity solutions, we offer end-to-end support that adapts to the evolving digital demands of modern enterprises.</p>
                        
                        <p>What sets Godavari Wave apart is our holistic approach to technology integration. We combine technical expertise with strategic insight, ensuring that every solution we develop not only addresses immediate needs but also positions our clients for future technological advancements. Our multidisciplinary team of experts brings together deep technical knowledge, creative problem-solving, and a commitment to excellence that transforms technological challenges into opportunities for growth and innovation.</p>
                        
                        <!-- <p>Whether you're looking to enhance user experience through intuitive design, ensure rigorous quality assurance, optimize IT operations, or fortify your digital infrastructure against emerging cybersecurity threats, Godavari Wave provides comprehensive, forward-thinking support at every stage of your technological journey.</p> -->
                    </div>
                
                    <!-- <a href="/about" class="box-btn">Discover More About Us</a> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/company/company-img.jpg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Video Popup -->
<!-- <div class="video-popup" *ngIf="isOpen">
    <div class="popup-inner">
        <iframe src="https://www.youtube.com/embed/XNQk1qt__Vw" title="Setup and Local Installation - React Nextjs Online Yoga Coaching &amp; Training Courses System" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <button (click)="closePopup()" type="button" class="close-btn">
            <i class='bx bx-x'></i>
        </button>
    </div>
</div> -->

<!-- <section class="client-area ptb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <span>Testimonials</span>
            <h2>What Our Client’s Say</h2>
        </div>
        <div class="client-wrap">
            <owl-carousel-o [options]="clientWrap">
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/1-(2).png" alt="img">
                        <p>Godavari Wave Technologies Pvt Ltd has been a game-changer for our business. Their team delivered a highly efficient and scalable solution that perfectly matched our requirements. The professionalism and dedication of their team are unparalleled. We highly recommend their services!.</p>
                        <h3>Rohit</h3>
                       
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/1-(3).png" alt="img">
                        <p>Working with Godavari Wave Technologies Pvt Ltd was a pleasure. Their innovative ideas and robust development processes helped us launch our project on time and with exceptional quality. The support and expertise they provided were simply outstanding.</p>
                        <h3>Hitesh</h3>
     
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/1-(5).png" alt="img">
                        <p>The software solution developed by Godavari Wave Technologies Pvt Ltd has significantly streamlined our operations. Their attention to detail, timely delivery, and customer-centric approach were remarkable. We look forward to collaborating with them again.
                        </p>
                        <h3>Khatri </h3>
                     
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/1-(4).png" alt="img">
                        <p>Godavari Wave Technologies Pvt Ltd exceeded our expectations in every way. Their technical expertise and commitment to excellence made the entire process seamless. They truly understand their clients’ needs and deliver top-notch results.</p>
                   
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section> -->