<div class="container">

<form formGroup="Formgroup" (ngsubmit)="submit">
    <label for="">firstname</label>
    <div> <input type="text" formcontrolname="firstname" name="firstname"></div>
    <label for="">lastname</label>
    <div><input type="text" formcontrolname="lastname" name="lastname"></div>
    <label for="">email</label>
    <div><input type="text" formcontrolname="email" name="email"></div>
    <label for="">mobilenumber</label>
    <div><input type="text" formcontrolname="mobilenumber"></div>
 <button class="submit">submit</button>
</form>
</div>